import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import HelmetSummary from "./HelmetSummary";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useResponsiveStyles } from "./Styles";
import i18n from "./i18n";
import {urls, currentLanguage} from "./config"

const CircularButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "white",
  color: "#333",
  width: 50,
  height: 50,
  borderRadius: "50%",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "8px",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  },
}));

const TopBar = ({ children, disable = false  }) => {
  // const { language } = useParams();
  const location = useLocation();
const lang = currentLanguage(location); // Get language parameter from URL
  const navigate = useNavigate();
  const { t } = useTranslation();
  const styles = useResponsiveStyles()
  const isMobileView = useMediaQuery({ maxWidth: 768 });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    const validLanguages = ['pt', 'en', 'es'];
    if (validLanguages.includes(lang)) {
      i18n.changeLanguage(lang);
      // console.log(`Language changed to: ${lang}`);
    } else {
      // console.log(`Invalid language detected: ${lang}`);
      // Optionally, set a fallback language
      i18n.changeLanguage('pt');
    }
  }, [lang]);


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (newLang,) => {
    
    // console.log(newLang);
    i18n.changeLanguage(newLang); // Change language in i18n
    if (!disable) {
    const currentPath = window.location.pathname
    .split("/")
    .filter((segment, index) => index !== 1) // Remove the lang segment (second segment in URL)
    .join("/");
    
    document.documentElement.lang = newLang
    
    // console.log(currentPath);
    navigate(`/${newLang}${currentPath}`); // Update URL with new language
    }
  };

  return (
    <div>
      <HelmetSummary />
      <div style={styles.topBar}>
        <div style={styles.languageSwitcher}>
        
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg"
            alt="English"
            style={styles.flag}
            onClick={() => handleLanguageChange("en")}
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Brazil.svg"
            alt="Português"
            style={styles.flag}
            onClick={() => handleLanguageChange("pt")}
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg"
            alt="Español"
            style={styles.flag}
            onClick={() => handleLanguageChange("es")}
          />

          {isMobileView && (
            <div>
              <CircularButton
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                ☰
              </CircularButton>
              <StyledMenu
                id="demo-customized-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} component={Link} to={`/${lang}${urls.home}`}>
                  {t("nav.home", { defaultValue: "Home" })}
                </MenuItem>
                {/* <MenuItem onClick={handleClose} component={Link} to={`/${lang}${urls.blog}`}>
                  {t("nav.blog", { defaultValue: "Blog" })}
                </MenuItem> */}
                <MenuItem onClick={handleClose} component={Link} to={`/${lang}${urls.about}`}>
                  {t("nav.about", { defaultValue: "About" })}
                </MenuItem>
                <MenuItem onClick={handleClose} component={Link} to={`/${lang}${urls.portfolio}`}>
                {t("nav.portfolio")}
                </MenuItem>
                <MenuItem onClick={handleClose} component={Link} to={`/${lang}${urls.os}`}>
                {t("nav.os", { defaultValue: "OS" })}
                </MenuItem>
                {/* <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to={`/${lang}${urls.courses}`}
                >
                  {t("nav.courses", { defaultValue: "Courses" })}
                </MenuItem> */}
              </StyledMenu>
            </div>
          )}
        </div>
        {!isMobileView && (
          <div style={styles.navContainer}>
            <Link to={`/${lang}${urls.home}`} style={styles.link}>
              {t("nav.home")}
            </Link>
            {/* <Link to={`/${lang}${urls.blog}`} style={styles.link}>
              {t("nav.blog", { defaultValue: "Blog" })}
            </Link> */}
            <Link to={`/${lang}${urls.about}`} style={styles.link}>
              {t("nav.about")}
            </Link>
            <Link to={`/${lang}${urls.portfolio}`} style={styles.link}>
            {t("nav.portfolio")}
            </Link>
            <Link to={`/${lang}${urls.os}`} style={styles.link}>
              {t("nav.os", { defaultValue: "OS" })}
            </Link>
            {/* <Link
              to={`/${lang}${urls.courses}`}
              style={styles.link}
            >
              {t("nav.courses")}
            </Link> */}
          </div>
        )}
      </div>
      <div>{children}</div>
    </div>
   
  );
};

export default TopBar;
