import i18n from "./i18n"
export const WHATSAPP_NUMBER = "5548991550710"
export const getCompleteWhatsAppNumber = (t) => {
    return `https://api.whatsapp.com/send?phone=${WHATSAPP_NUMBER}&text=${getWhatsAppMessage(t)}`;
};

export const getWhatsAppMessage= (t) => {
    return `${t("whatsapp.initialMessageByClient")}${window.location.href}`;
};



export const currentLanguage = (location) => {
    // cancelAnimationFrame
    const pathSegments =  window.location.pathname.split("/");
    const currentLanguage =  pathSegments[1] || "pt"; // Defaults to "en" if no language segment is present
    document.documentElement.lang = currentLanguage
    const validLanguages = ['pt', 'en', 'es'];
    if (validLanguages.includes(currentLanguage)) {
    return currentLanguage
    }
    return i18n.language  || "pt"
    
  };
  
  const LOCAL_API_KEY = "AIzaSyDR4NNrZrB_xTmGmNog-6kwRLqiVfLiPHU";
  const PRODUCTION_API_KEY = "AIzaSyDprlbOIA4dcQfCbsUsS6L2QBAwcfU5ePs";

export const MAPS_API_KEY =
window.location.hostname === "digitalconte.com"
    ? PRODUCTION_API_KEY
    : LOCAL_API_KEY;


export const availableLanguages = ["pt", "en", "es"]; // Define supported languages


const blog = "saiba-como-cuidar-do-seu-celular"

export const urls = {
      home: "/assistencia-tecnica/florianopolis/centro",
      about: "/assistencia-tecnica/florianopolis/centro/nossos-diferenciais",
      blog: `/assistencia-tecnica/florianopolis/centro/${blog}`,
      blogPost: `/assistencia-tecnica/florianopolis/centro/${blog}/:postId`,
      location: "/assistencia-tecnica/florianopolis/centro/como-trazer-seu-celular-ate-nos",
      login: "/assistencia-tecnica/florianopolis/centro/acesse-a-area-logada",
      portfolio: "/assistencia-tecnica/florianopolis/centro/o-que-consertamos",
      os: "/assistencia-tecnica/florianopolis/centro/conserto/os/",

      prop: "/assistencia-tecnica/florianopolis/centro/concerto/digital-conte-florianopolis-do-proprietario/", // concerto rs
      ava: "/assistencia-tecnica/florianopolis/centro/concerto/avaliacoes-sobre-digital-conte-florianopolis/", // concerto rs
      prod: "/assistencia-tecnica/florianopolis/centro/concerto/produtos-oferecidos-por-digital-conte-florianopolis/", // concerto rs
      serv: "/assistencia-tecnica/florianopolis/centro/concerto/servicos-oferecidos-por-digital-conte-florianopolis/", // concerto rs

      marca: "/assistencia-tecnica/florianopolis/centro/conserto/marca/",
    }
