import { urls } from "./config";

const jsonFiles = require.context(
  "./assets/info/",
  true, // Enable recursive search
  /\.json$/ // Match only JSON files
);
export interface DeviceInfo {
    name: string;
    type: string;
    marca: string;
    lancamento: string;
    espessura: string;
    dimensoes: string;
    peso: string;
    telaTipo: string;
    telaTamanho: string;
    telaProp: string;
    telaRes: string;
    ppi: string;
    cores: string;
    resistencia: string;
    bateriaTipo: string;
    bateriaCapacidade: string;
    carregadorWatts: string;
    cargaSemFio: string | null;
    autonomiaConversacao: string;
    autonomiaStandby: string;
    wifiTipo: string;
    cameraPrincipal: string;
    cameraPrincipalRes: string;
    videoPrincipal: string;
    cameraFrontalRes: string;
    videoFrontal: string;
    brand: string;
    modelName: string;
    so: string;
    deviceType: string;
    intro: string[];
}

export const DefautRouteType = "tela-quebrada";

export const generateRoutesFromFiles = (type = DefautRouteType) => {
  const routes = jsonFiles.keys().map((filePath) => {
    const data = jsonFiles(filePath);
    const relativePath = filePath
      .replace("./", "")
      .replace(".json", "")
      .replace("/assets/info/", "");

    const routePath = `${urls.home}/conserto/${type}/${relativePath}/`;

    return {
      path: `${routePath}`,
      data,
    };
  });

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  const validRoutes = routes.filter((route) => parseDate(route.data.lancamento) !== null);

  validRoutes.sort((a, b) => {
    const dateA = parseDate(a.data.lancamento);
    const dateB = parseDate(b.data.lancamento);
    return dateB.getTime() - dateA.getTime();
  });

  return validRoutes;
};
