import React, { useState, useEffect } from "react";
import osData from "./assets/info/os.json"; // Import OS data
import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import { ptBR, enUS, es } from "date-fns/locale";
import i18n from "./i18n";
import { useResponsiveStyles } from "./Styles";
// import { urls } from "./config";

const OSs = () => {
  const { t } = useTranslation();
  const [osList, setOsList] = useState([]);
  const { language } = useParams();
  const lang = language ?? "pt";

  const getLocale = (language) => {
    switch (language) {
      case "en":
        return enUS;
      case "es":
        return es;
      default:
        return ptBR;
    }
  };

  useEffect(() => {
    // Process and sort OS data by date
    const processedOsList = osData
      .map((osItem) => ({
        ...osItem,
        date: osItem.os.date ? new Date(osItem.os.date.split("/").reverse().join("-")) : null,
      }))
      .sort((a, b) => (b.date || 0) - (a.date || 0)); // Sort by descending date

    setOsList(processedOsList);
  }, []);

  const styles = useResponsiveStyles();

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>{t("os.title", "Ordens de Serviço")}</h1>
      <p style={styles.intro}>
        {t("os.intro", "Veja as ordens de serviço mais recentes e detalhes de cada reparo.")}
      </p>
      {osList.map((osItem, index) => (
        <div key={index} style={styles.postContainer}>
          <h2 style={styles.postTitle}>
            {osItem.os.title || t("os.defaultTitle", "Ordem de Serviço")}
          </h2>
          <p style={styles.postDate}>
            {osItem.date
              ? format(osItem.date, "PPPP", { locale: getLocale(lang || i18n.language) })
              : t("os.noDate", "Data não disponível")}
          </p>
          <p style={styles.postContent}>
            {osItem.os.comment_clean?.substring(0, 150) || t("os.noDetails", "Detalhes não disponíveis")}...
          </p>
          <div style={styles.actionButtons}>
            <Link to={osItem.url} style={styles.nativeShareButton}>
              <FaArrowRight style={styles.platformIcon} />
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OSs;
