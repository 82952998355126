import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useResponsiveStyles } from "./Styles";
import { generateRoutesFromFiles } from "./dynamicRoutes";
import { Link, useLocation } from "react-router-dom";
import { getCompleteWhatsAppNumber } from "./config";
import {VariateSection} from "./VariateSection"
import osData from "./assets/info/os.json";

const EachDevice = ({ route }, { index }) => {
  const styles = useResponsiveStyles();
  return (
    <div key={index} style={styles.card}>
      <div style={styles.deviceRow}>
        <span style={styles.deviceName}>{route.data.name || "Desconhecido"}</span>
        <div style={styles.iconLinksContainer}>
          <Link to={route.path} style={styles.iconLink}>
            <div style={styles.iconBox}>
              <i className="fas fa-mobile-alt" style={styles.platformIcon}></i>
            </div>
          </Link>
          <Link
            to={route.path.replace("tela-quebrada", "trocar-bateria")}
            style={styles.iconLink}
          >
            <div style={styles.iconBox}>
              <i className="fas fa-battery-full" style={styles.platformIcon}></i>
            </div>
          </Link>
          <Link
            to={route.path.replace("tela-quebrada", "trocar-camera")}
            style={styles.iconLink}
          >
            <div style={styles.iconBox}>
              <i className="fas fa-camera" style={styles.platformIcon}></i>
            </div>
          </Link>
          <Link
            to={route.path.replace("tela-quebrada", "vidro-quebrado")}
            style={styles.iconLink}
          >
            <div style={styles.iconBox}>
              <i className="fas fa-window-maximize" style={styles.platformIcon}></i>
            </div>
          </Link>
          <Link
            to={route.path.replace("tela-quebrada", "molhou")}
            style={styles.iconLink}
          >
            <div style={styles.iconBox}>
              <i className="fas fa-tint" style={styles.platformIcon}></i>
            </div>
          </Link>
          <Link
            to={route.path.replace("tela-quebrada", "todos-problemas")}
            style={styles.iconLink}
          >
            <div style={styles.iconBox}>
              <i className="fas fa-tools" style={styles.platformIcon}></i>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const RelatedRecordsGrid = ({ relatedRecords }) => {
  const styles = useResponsiveStyles();

  return (
    <div style={styles.grid}>
      {relatedRecords.map((record, index) => (
        <RecordCard key={index} record={record} />
      ))}
    </div>
  );
};
const RecordCard = ({ record }) => {
  const styles = useResponsiveStyles();

  return (
    <Link
      to={record.url}
      style={{
        textDecoration: "none",
        color: "inherit",
        padding: "15px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.2s, box-shadow 0.2s",
        display: "block",
        marginBottom: "15px",
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "scale(1.02)";
        e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "scale(1)";
        e.currentTarget.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.1)";
      }}
    >
      <div>
        <strong
          style={{
            fontSize: "1.1rem",
            display: "block",
            marginBottom: "10px",
            color: "#333",
          }}
        >
          {record.os.title || "Título não disponível"}
        </strong>
        <p style={{ color: "#555", fontSize: "0.9rem", margin: "0" }}>
          {record.os.comment_clean || "Descrição não disponível"}
        </p>
        <p style={{ color: "#777", fontSize: "0.8rem", marginTop: "10px" }}>
          <strong>Data:</strong> {record.os.date || "Data não disponível"}
        </p>
        <p style={{ color: "#777", fontSize: "0.8rem", margin: "0" }}>
          <strong>Número da OS:</strong> {record.os.os || "Não especificado"}
        </p>
        <p style={{ color: "#777", fontSize: "0.8rem", margin: "0" }}>
          <strong>Cliente:</strong> {record.os.client || "Nome não disponível"}
        </p>
      </div>
    </Link>
  );
};

export const RelatedMarca = ({ marca }) => {
  const styles = useResponsiveStyles();

  // Filtra os registros com a mesma marca
  const relatedRecords = osData.filter(
    (os) => os.device.brand.toLowerCase() === marca.toLowerCase()
  );

  return (
    <div>
      {relatedRecords.length > 0 && (
        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>
            {`Veja nosso registro de conserto da marca ${marca}`}
          </h2>
          <RelatedRecordsGrid relatedRecords={relatedRecords} />
        </div>
      )}
    </div>
  );
};



// export default EachDevice;


const EspecialistaMarca = ({ brand }) => {
  const { t } = useTranslation();
  const styles = useResponsiveStyles();
  const location = useLocation();

  const [searchQuery, setSearchQuery] = useState("");

  const routes = generateRoutesFromFiles();
  const filteredDevices = routes.filter(
    (route) => route.data.brand?.toLowerCase() === brand?.toLowerCase()
  );
  const jsonData = {
    marca: {brand}
  }

  const filteredBySearch = filteredDevices.filter((device) =>
    device.data.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div style={styles.container}>
      <Helmet>
        <title>{`Especialistas em ${brand} | Florianópolis Centro`}</title>
        <meta
          name="description"
          content={t(
            "specialist.description",
            `Especialistas em troca de tela e manutenção de dispositivos da marca ${brand} no centro de Florianópolis. Garanta qualidade e segurança com garantia de até 12 meses.`
          )}
        />
        <meta
          name="keywords"
          content={`troca de tela ${brand}, conserto ${brand}, concerto, conserto de celular ${brand}, reparo de tela, troca de bateria,assistência técnica ${brand}`}
        />
        <meta name="author" content="Digital Conte" />
      </Helmet>
      <h1 style={styles.heading}>{`${t("specialist.heading", "Especialistas em")} ${brand}`}</h1>
      <p style={styles.description}>
        {t(
          "specialist.intro",
          `Se você está procurando um serviço confiável para consertar seu dispositivo ${brand}, você está no lugar certo. Na Digital Conte, combinamos experiência, qualidade e garantia para entregar o melhor serviço possível.`
        )}
      </p>
      
      <input
        type="text"
        placeholder={t("specialist.searchPlaceholder", "Pesquisar dispositivos...")}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={styles.searchBar}
      />

      {filteredBySearch.length > 0 ? (
        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>{t("specialist.devices", "Modelos Disponíveis")}</h2>
          <div style={styles.grid}>
            {filteredBySearch.map((route, index) => (
              <EachDevice  route={route} index={index}/>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <p style={styles.noResults}>
            {t("specialist.noResults", "Nenhum dispositivo encontrado para essa marca.")}
          </p>
          <div style={styles.containerwhatsapp}>
            <a
              style={styles.whatsappButton}
              href={getCompleteWhatsAppNumber(t)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                if (typeof window.gtag === "function") {
                  window.gtag("event", `whatsapp_click_conserto_tela_${brand}`, {
                    event_category: "engagement",
                    event_label: location.pathname,
                    value: 1,
                  });
                }
              }}
            >
              <i className="fab fa-whatsapp"></i> {t("header.button")}
            </a>
          </div>
        </div>
      )}
      
      <VariateSection jsonData={jsonData}/>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>{t("specialist.serviceProcess", "Como funciona nosso processo de reparo?")}</h2>
        <p style={styles.description}>
          {t(
            "specialist.process",
            "Na Digital Conte, todo o processo de reparo é transparente e rápido. Veja como funciona:"
          )}
        </p>
        <ol style={styles.list}>
          <li style={styles.listItem}>
            <strong>{t("specialist.step1", "Avaliação Detalhada")}</strong> – Realizamos um diagnóstico completo do problema da tela ou bateria.
          </li>
          <li style={styles.listItem}>
            <strong>{t("specialist.step2", "Reparo ou Substituição")}</strong> – Oferecemos a melhor solução, utilizando peças de qualidade.
          </li>
          <li style={styles.listItem}>
            <strong>{t("specialist.step3", "Garantia e Entrega Rápida")}</strong> – Garantimos o reparo no menor tempo possível, sem comprometer a qualidade.
          </li>
        </ol>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>{t("specialist.contactUs", "Entre em Contato")}</h2>
        <p style={styles.description}>
          {t(
            "specialist.contactDescription",
            `Pronto para consertar seu ${brand}? Entre em contato conosco agora mesmo e aproveite o melhor serviço de troca de tela e baterias da região.`
          )}
        </p>
        <div style={styles.containerwhatsapp}>
                <a
                    style={styles.whatsappButton}
                    href={getCompleteWhatsAppNumber(t)}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        if (typeof window.gtag === "function") {
                            window.gtag("event", `whatsapp_click_troca_bateria_${jsonData.deviceType}_${jsonData.modelName}`, {
                                event_category: "engagement",
                                event_label: location.pathname,
                                value: 1,
                            });
                        }
                    }}
                >
                    <i className="fab fa-whatsapp"></i> {t('header.button')}
                </a>
            </div>
        <RelatedMarca marca={brand} />
      </div>
    </div>
  );
};

export {EspecialistaMarca , EachDevice, RelatedRecordsGrid};
