import React from "react";
import Home from "./Home";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import TopBar from "./Topbar"
import EmptyTopBar from "./EmptyTopBar"
// import translations from "./locales/pt/translation.json";
import Footer from "./Footer"
import { generatedText } from "./TextFooter";

const Campaign = ({ customTexts, disable=true  }) => {
    // const customTexts = {
    //     "header.title": "Retiramos e Entregamos",
    //     "header.description": "Agende uma visita Fale conosco",
    //   }
    const { t } = useTranslation();
    // const t = (key) => customTexts[key] || originalT(key)
    const services = t("about.services", { returnObjects: true }) || []; // Ensure it's an array
    //   console.log(services)
    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": customTexts["header.title"] || t("header.title"), 
        "description": customTexts["header.description"] || generatedText,
        "url": "https://digitalconte.com",
        "telephone": "+5548991550710",
        "logo": "https://digitalconte.com/assets/images/logo-preto-fundo-branco.png",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": t("header.address").split(",")[0],
          "addressLocality": "Florianópolis",
          "addressRegion": "SC",
          "postalCode": t("header.address").split(",")[-1],
          "addressCountry": "BR",
        },
        "openingHours": t("header.hours").replaceAll("das", " ").replaceAll(" às ", "-").replace("Segunda a Sexta", "Mo-Fr").replace("e Sábado", ", Sa").replaceAll("  ", " "),
        "sameAs": [
          "https://www.instagram.com/digitalcontefloripa/",
          "https://maps.google.com/maps?q=Digital%20Conte%20Reparo%20Florianopolis",
          "https://linktr.ee/digitalconteofc",
        ],
     "service": services.map((service) => ({
      "@type": "Service",
      "name": service,
      "description": `Conserto de ${service}, tablet, smartwatch e informática. Trabalhamos com marcas como Apple, Samsung, Xiaomi e Motorola em Florianópolis - Centro.`,
         }
        ))
        ,
      };
    
      return (
        <div>
             <TopBar disable={disable}>
             <EmptyTopBar />
          
            <Home />
          <Footer />
            </TopBar>
            <Helmet>

            <title>{t("header.title")}</title>
            <meta name="description" content={generatedText} />
            <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
            </Helmet>
         
          
        </div>
      );
    }
    
export default Campaign