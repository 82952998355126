const categories = {
    location: [
      "Localizada no centro de Florianópolis",
      "Com fácil acesso no centro de Florianópolis",
      "No coração de Florianópolis, no centro",
      "Uma loja no centro de Florianópolis",
      "Uma loja de celular centro Florianópolis",
      "Para conserto de celular no centro de Florianópolis",
      "Quando nos referimos a assistência técnica de celular centro",
    ],
    services: [
      "realizamos troca de tela de celular",
      "oferecemos reparo de câmera e componentes internos",
      "especializamos em conserto de celulares molhados",
      "troca de bateria de iPhone",
      "reparo em celular",
      "manutenção para dispositivos Apple",
      "substituição de telas para iPhones",
      "reparo de Apple Watch",
    ],
    brands: [
      "assistência técnica para dispositivos Apple",
      "serviços especializados em celulares Samsung",
      "reparo profissional para smartphones Motorola",
      "assistência técnica Xiaomi Florianópolis",
      "autorizada Apple Florianópolis",
      "autorizada iPhone Florianópolis",
      "assistência técnica LG Florianópolis",
    ],
    accessories: [
      "uma linha completa de capas e películas de proteção",
      "carregadores e acessórios de alta qualidade",
      "acessórios que prolongam a vida útil do seu dispositivo",
      "loja de capa de celular no centro de Florianópolis",
      "acessórios de celular para proteção e funcionalidade",
    ],
    questions: [
      "como ativar o botão virtual do iPhone",
      "se a bateria de celular inchada é perigosa",
      "onde consertar a tela do celular",
      "como evitar danos em smartphones",
      "qual a melhor assistência técnica para iPhones",
    ],
  };

  const selectKeyword = (category) => {
    const index = Math.floor(Math.random() * category.length);
    return category[index];
  };

  export const generatedText = `
    ${selectKeyword(categories.location)}, a Digital Conte é referência em assistência técnica de celulares. Aqui, ${selectKeyword(categories.services)} com qualidade e rapidez. Somos especialistas em ${selectKeyword(categories.brands)} e utilizamos peças de alta qualidade para garantir o melhor desempenho. Além disso, contamos com ${selectKeyword(categories.accessories)} para proteger e complementar o uso do seu smartphone. Tem dúvidas sobre ${selectKeyword(categories.questions)}? Nossa equipe está pronta para ajudar. Venha conhecer nossos serviços!`;