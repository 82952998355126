import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useResponsiveStyles } from "./Styles";
import { getCompleteWhatsAppNumber } from "./config";
import { useLocation } from "react-router-dom";

function Home() {
  const { t } = useTranslation();
  const styles = useResponsiveStyles();
  const feedbackRef = useRef(null);
  const location = useLocation()

  useEffect(() => {
    const loadFeedbackScript = () => {
      const script = document.createElement("script");
      script.src = "https://featurable.com/assets/bundle.js";
      script.defer = true;
      script.charset = "UTF-8";
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadFeedbackScript();
          observer.disconnect(); // Stop observing once loaded
        }
      },
      { rootMargin: "100px" }
    );

    if (feedbackRef.current) {
      observer.observe(feedbackRef.current);
    }
  }, []);

  return (
    <div className="App">
      <div>
        <div style={styles.section}>
          <header style={styles.header}>
            <div style={styles.welcomeText}>
              <h2 style={styles.welcomeTextH2}>{t("header.welcome")}</h2>
              <h1 style={styles.welcomeTextH1}>{t("header.title")}</h1>
              <p style={styles.welcomeTextP}>{t("header.description")}</p>
              <div style={styles.containerwhatsapp}>
                <a
                  style={styles.whatsappButton}
                  href={getCompleteWhatsAppNumber(t)}
  target="_blank"
  rel="noopener noreferrer"
  onClick={() => {
    if (typeof window.gtag === "function") {
      window.gtag("event", "whatsapp_click_home", {
        event_category: "engagement",
        event_label: location.pathname,
        value: 1,
      });
    }
  }}
>
                  <i className="fab fa-whatsapp"></i> {t("header.button")}
                </a>
              </div>
              <div style={styles.contactInfo}>
                <p>
                  <i className="far fa-clock" style={styles.contactInfoIcon}></i>{" "}
                  {t("header.hours")}
                </p>
                <p>
                  <i className="fas fa-map-marker-alt" style={styles.contactInfoIcon}></i>{" "}
                  {t("header.address")}
                </p>
              </div>
            </div>
            <div style={styles.headerImage}></div>
          </header>
        </div>

        <div style={styles.whiteSection}>
          <h2 style={styles.title}>{t("differentials.title")}</h2>
          <div style={styles.grid}>
            {Object.entries(t("differentials.items", { returnObjects: true })).map(([icon, text], index) => (
              <div style={styles.card} key={index}>
                <i className={`${icon}`} style={styles.differentialIcon}></i>
                <p style={styles.differentialText}>{text}</p>
              </div>
            ))}
          </div>
        </div>

        <div style={styles.section}>
        <h2 style={styles.title}>{t("about.servicesTitle", "Serviços oferecidos")}</h2>
        <div style={styles.grid}>
          {t("about.services", { returnObjects: true }).map((service, index) => (
            <div key={index} style={styles.differential}>
              <i className="fas fa-check-circle" style={styles.differentialIcon}></i>
              <p style={styles.differentialText}>{service}</p>
            </div>
          ))}
        </div>
      </div>

      </div>
    </div>
  );
}

export default Home;
