import React from "react";
import { FaTools, FaShieldAlt, FaClock } from "react-icons/fa";
import { useResponsiveStyles } from "./Styles";
import sections from "./variations.json";
import { useTranslation } from "react-i18next"
import { getCompleteWhatsAppNumber } from "./config";
import { useLocation, Link } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import {DeviceInfo} from "./dynamicRoutes"
import osData from "./assets/info/os.json";
import {RelatedRecordsGrid, EachDevice} from "./EspecialistaMarca"
import { generateRoutesFromFiles } from "./dynamicRoutes";



export const RelatedRepairsSection = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const styles = useResponsiveStyles();

  // Hash generation function based on URL and current month
  const generateMonthlyHash = (url) => {
    const date = new Date();
    const month = date.getMonth() + 1; // Add 1 because getMonth is zero-based
    const year = date.getFullYear();
    const seed = `${url}-${month}-${year}`; // Combine URL and month-year
    let hash = 0;
    for (let i = 0; i < seed.length; i++) {
      hash = (hash << 5) - hash + seed.charCodeAt(i);
      hash |= 0; // Convert to 32-bit integer
    }
    return Math.abs(hash); // Ensure positive hash
  };

  const routes = generateRoutesFromFiles();
  const hash = generateMonthlyHash(location.pathname);
  const filteredRoutes = routes
    .sort((a, b) => hash % a.path.length - hash % b.path.length) // Shuffle based on hash
    .slice(0, 5); // Take the first 5 routes

  return (
    <div style={styles.section}>
      <h2 style={styles.sectionTitle}>Veja outros dispositivos que consertamos</h2>
      <div style={styles.grid}>
        {filteredRoutes.map((route, index) => (
          <EachDevice key={index} route={route} index={index} />
        ))}
      </div>
    </div>
  );
};

const calculateHash = (input, offset) => {
  return Math.abs(
    input.split("").reduce((acc, char) => acc + char.charCodeAt(0) + offset, 0)
  );
};

export const VariateSection = ({ jsonData }) => {
  const styles = useResponsiveStyles();
  const location = useLocation();
  const pathname = location.pathname;

  const section = sections[0];

  // Calculate individual hashes
  const descriptionHash = calculateHash(pathname, 0);
  const toolsHash = calculateHash(pathname, 1);
  const shieldAltHash = calculateHash(pathname, 2);
  const clockHash = calculateHash(pathname, 3);

  // Select deterministic variations
  const description = section.description[descriptionHash % section.description.length];
  const faTools = section.FaTools[toolsHash % section.FaTools.length];
  const faShieldAlt = section.FaShieldAlt[shieldAltHash % section.FaShieldAlt.length];
  const faClock = section.FaClock[clockHash % section.FaClock.length];

  return (
    <div style={styles.section}>
      <h2 style={styles.sectionTitle}>{section.title}</h2>
      <p style={styles.description}>{description.replace("{marca}", jsonData.marca)}</p>
      <div style={styles.grid}>
        <div style={styles.differential}>
          <FaTools style={styles.differentialIcon} />
          <p style={styles.differentialText}>{faTools.replace("{marca}", jsonData.marca)}</p>
        </div>
        <div style={styles.differential}>
          <FaShieldAlt style={styles.differentialIcon} />
          <p style={styles.differentialText}>{faShieldAlt.replace("{marca}", jsonData.marca)}</p>
        </div>
        <div style={styles.differential}>
          <FaClock style={styles.differentialIcon} />
          <p style={styles.differentialText}>{faClock.replace("{marca}", jsonData.marca)}</p>
        </div>
      </div>
    </div>
  );
};
 
export const Related = ({ jsonData }: { jsonData: DeviceInfo }) => {
  // const { t } = useTranslation();
  // const location = useLocation();
  const dateLanc = format(new Date(jsonData.lancamento.split("/").reverse().join("-")), "PPP", {
    locale: ptBR,
  });

  const styles = useResponsiveStyles();
  // const whatsappLink = getCompleteWhatsAppNumber(t);

  // Filtra os registros do mesmo modelo
  const relatedRecords = osData.filter((os) => {
  

  const isSameBrand = os.device.marca.toLowerCase() === jsonData.marca.toLowerCase();
  const isSameDevice = os.device.modelName.toLowerCase() === jsonData.modelName.toLowerCase();

  return isSameBrand && isSameDevice;
});

  return (
    <div >
      {/* <h2> aqui </h2> */}
     <div>
       {relatedRecords.length > 0 && (
         <div style={styles.section}>
           <h2 style={styles.sectionTitle}>
             {`Veja nosso registro de conserto do ${jsonData.name}`}
           </h2>
           <RelatedRecordsGrid relatedRecords={relatedRecords} />
         </div>
       )}
     </div>
     </div>
   );
 };