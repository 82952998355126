import React from "react";
import { Helmet } from "react-helmet";
import { FaTools, FaShieldAlt, FaClock, FaCheckCircle } from "react-icons/fa";
import { useResponsiveStyles } from "./Styles";
import { useTranslation } from "react-i18next"
import { getCompleteWhatsAppNumber } from "./config";
import { useLocation, Link } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { urls } from "./config";
import {DeviceInfo} from "./dynamicRoutes"
import {VariateSection, Related, RelatedRepairsSection} from "./VariateSection"


export const ConsertarTelas =({ jsonData }: { jsonData: DeviceInfo }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const dateLanc = format(new Date(jsonData.lancamento.split("/").reverse().join("-")), "PPP", { locale: ptBR });

    const styles = useResponsiveStyles();
    const whatsappLink = getCompleteWhatsAppNumber(t);

  return (
    <div style={styles.container}>
        
      <Helmet>
        <title>{`Conserto de Tela ${jsonData.deviceType} ${jsonData.name} | Especialistas em ${jsonData.marca} - Digital Conte Florianópolis`}</title>
        <meta
          name="description"
          content={`Conserto profissional da tela do seu ${jsonData.name} em Florianópolis. Técnicos certificados, peças originais e garantia de qualidade. Entre em contato!`}
        />
        <meta
          name="keywords"
          content={`conserto de tela, concerto, tela quebrada, troca de tela, reparo de tela, ${jsonData.name}, ${jsonData.marca}, assistência técnica, Florianópolis, peças originais, técnicos certificados`}
        />
      </Helmet>

      <h1 style={styles.heading}>{`Conserto de Tela ${jsonData.deviceType} ${jsonData.name}`}</h1>
      <Link to={`/pt${urls.marca}${jsonData.marca.toLowerCase()}`} style={styles.backButton}>
                {t("backButton", `← Voltar para a lista de da marca ${jsonData.marca.toLowerCase()}`)}
            </Link>
      <div style={styles.containerwhatsapp}>
        <a
                style={styles.whatsappButton}
                href={getCompleteWhatsAppNumber(t)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  if (typeof window.gtag === "function") {
                    window.gtag("event", `whatsapp_click_conserto_tela_${jsonData.deviceType}_${jsonData.modelName}`, {
                      event_category: "engagement",
                      event_label: location.pathname,
                      value: 1,
                    });
                  }
                }}
              >
                <i className="fab fa-whatsapp"></i> {t('header.button')}
              </a>
              </div>

      <h2 style={styles.description}>
        Somos especialistas em {jsonData.marca} e conserto de tela de {jsonData.name}. Sabemos o quanto é frustrante lidar com uma tela danificada, especialmente em um dispositivo tão icônico como o {jsonData.name}, lançado em {dateLanc} pela {jsonData.marca}. Nossa missão é trazer de volta a funcionalidade e a beleza original do seu {jsonData.name}, proporcionando um serviço de alta qualidade e confiança.
      </h2>

      <p style={styles.description}>
        O {jsonData.name} é um {jsonData.deviceType} que se destaca por suas especificações impressionantes. Equipado com uma tela {jsonData.telaTipo} de {jsonData.telaTamanho}, oferece uma resolução de {jsonData.telaRes} com densidade de {jsonData.ppi}, capaz de exibir até {jsonData.cores} de cores. Suas dimensões de {jsonData.dimensoes}, com espessura de apenas {jsonData.espessura} e peso de {jsonData.peso}, tornam este dispositivo extremamente portátil e elegante.
      </p>


      <p style={styles.description}>
         Nós entendemos a importância do seu {jsonData.name} no seu dia a dia, seja para trabalho, estudos ou entretenimento. Por isso, nossa equipe de técnicos certificados está pronta para reparar a tela do seu dispositivo com a máxima eficiência e cuidado. Utilizamos apenas peças originais, garantindo que seu {jsonData.name} funcione como novo após o conserto.
      </p>

      <p style={styles.description}>
        Na Digital Conte Florianópolis, priorizamos a satisfação do cliente. Nosso compromisso é oferecer um serviço rápido, confiável e com garantia de qualidade. Cada detalhe é importante para nós, e nos dedicamos a devolver o seu {jsonData.name} em perfeitas condições.
      </p>


      <VariateSection jsonData={jsonData} />

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>Especificações técnicas da Tela do {jsonData.name}</h2>
        <p style={styles.description}>
          Conhecemos a fundo as especificações técnicas do seu dispositivo, garantindo um serviço de qualidade e compatibilidade total.
        </p>
        <div style={styles.grid}>
          <div style={styles.differential}>
            <FaCheckCircle style={styles.differentialIcon} />
            <p style={styles.differentialText}>{`Tipo: ${jsonData.telaTipo}`}</p>
          </div>
          <div style={styles.differential}>
            <FaCheckCircle style={styles.differentialIcon} />
            <p style={styles.differentialText}>{`Tamanho: ${jsonData.telaTamanho}`}</p>
          </div>
          <div style={styles.differential}>
            <FaCheckCircle style={styles.differentialIcon} />
            <p style={styles.differentialText}>{`Resolução: ${jsonData.telaRes}`}</p>
          </div>
          <div style={styles.differential}>
            <FaCheckCircle style={styles.differentialIcon} />
            <p style={styles.differentialText}>{`Densidade: ${jsonData.ppi}`}</p>
          </div>
          <div style={styles.differential}>
            <FaCheckCircle style={styles.differentialIcon} />
            <p style={styles.differentialText}>{`Resistência: ${jsonData.resistencia}`}</p>
          </div>
        </div>
      </div>
                <Related jsonData={jsonData} />
      <h2 style={styles.sectionTitle}>Entre em contato</h2>
      <p style={styles.description}>
        Não deixe que uma tela danificada atrapalhe o seu dia. Entre em contato conosco agora mesmo e traga seu {jsonData.name} para uma avaliação. Estamos prontos para atendê-lo e devolver a funcionalidade total do seu dispositivo.
      </p>
      <div style={styles.containerwhatsapp}>
        <a
                style={styles.whatsappButton}
                href={getCompleteWhatsAppNumber(t)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  if (typeof window.gtag === "function") {
                    window.gtag("event", `whatsapp_click_conserto_${jsonData.deviceType}_${jsonData.modelName}`, {
                      event_category: "engagement",
                      event_label: location.pathname,
                      value: 1,
                    });
                  }
                }}
              >
                <i className="fab fa-whatsapp"></i> {t('header.button')}
              </a>
              </div>
               <RelatedRepairsSection />
    </div>
  );
};

export default ConsertarTelas;
